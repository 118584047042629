export const tab_en = {
  "mqs_orderheader_tab0": "mqs_orderheader_tab0",
  "mqs_orderheader_tab1": "mqs_orderheader_tab1",
  "mqs_orderheader_tab1a": "mqs_orderheader_tab1a",
  "mqs_orderheader_tab1b": "mqs_orderheader_tab1b",
  "mqs_orderheader_tab2": "mqs_orderheader_tab2",
  "mqs_orderheader_tab3": "mqs_orderheader_tab3",
  "mqs_orderheader_tab4": "mqs_orderheader_tab4",
  "mqs_orderheader_tab5": "mqs_orderheader_tab5",
  "mqs_orderheader_tab6": "mqs_orderheader_tab6",
  "mqs_orderheader_tab7": "mqs_orderheader_tab7",
  "user_def_oh_tab0": "user_def_oh_tab0",
  "user_def_oh_tab1": "user_def_oh_tab1",
};

export const tab_zh = {
  "mqs_orderheader_tab0": "综合",
  "mqs_orderheader_tab1": "杂项",
  "mqs_orderheader_tab1a": "进口税和消费税",
  "mqs_orderheader_tab1b": "用户自定义",
  "mqs_orderheader_tab2": "交货详细信息",
  "mqs_orderheader_tab3": "送货地址",
  "mqs_orderheader_tab4": "发票地址",
  "mqs_orderheader_tab5": "Hub地址",
  "mqs_orderheader_tab6": "财务",
  "mqs_orderheader_tab7": "财务2",
  "user_def_oh_tab0": "用户自定义的_设置1",
  "user_def_oh_tab1": "用户自定义的_设置2",
}
