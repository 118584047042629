import {YN, STATUS, CLIENT, ClientTimeUnit, PAGER} from '@constant';
import {createTimeoutPromise, dateFormat} from "@utils/utils";
import {ORDER_HEADER_MOVE_TASK_STATUS, ORDER_HEADER_ORDER_TYPE, ORDER_HEADER_STATUS} from "../../../../../../constant";
import {setSelectClientList, setSelectOwnerList, setSelectSiteList, setTermsOwnerList} from "../../../../../../api/common";
import {GetPageTableColumnItem} from "../../../../../../api/country";


const defaultPaginationInfo = {
  PAGE_NUM: PAGER.MIN_PAGE_NUM,
  PAGE_SIZE: PAGER.MAX_PAGE_SIZE
}

export const getFormConfig = (vm) => {
  return {
    filterFormConfig: [{
      "key": "STATUS",
      "label": "状态 ",
      "type": "select",
      "placeholder": "请选择状态 ",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": ORDER_HEADER_STATUS,
      "formatter": ({cellValue, row, column}) => {
        let res = ORDER_HEADER_STATUS.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    },{
      "key": "ORDER_ID",
      "label": "订单号",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入订单号 ",
      "tabGroup": "THead",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
    },{
      "key": "CLIENT_ID",
      "label": "客户端",
      "type": "magnifier",
      "placeholder": "请选择客户端",
      "tabGroup": "THead",
      "tabGroupName": "综合",
      defaultUpper:true,
      "showBlock": [
        "all"
      ],
      "checked": false,
    },{
      "key": "INSTRUCTIONS",
      "label": "订单备注",
      "type": "textarea",
      "placeholder": "请输入订单备注 ",
      "tabGroup": "THead",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "col": "2",
    }, {
      "key": "MOVE_TASK_STATUS",
      "label": "移动任务状态 ",
      "type": "select",
      "placeholder": "请选择移动任务状态 ",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": ORDER_HEADER_MOVE_TASK_STATUS,
      "formatter": ({cellValue, row, column}) => {
        let res = ORDER_HEADER_MOVE_TASK_STATUS.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "OWNER_ID",
      "label": "所有者",
      "type": "magnifier",
      "placeholder": "请选择所有者",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      // "options": async () => {
      //   let {status, data: {list}, message} = await setSelectOwnerList({
      //     WORKSTATION: {},
      //     ...defaultPaginationInfo
      //   });
      //
      //   if (Number(status) === 200) {
      //     return list.map(item=>{
      //       return{
      //         key:item.OWNER_ID,
      //         label:item.OWNER_ID
      //       }
      //     })
      //   }
      // },
    }, {
      "key": "FROM_SITE_ID",
      "label": "始发站ID",
      "type": "select",
      "placeholder": "请选择始发站ID",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": async () => {
        let {status, data: {list}, message} = await setSelectSiteList({
          SITE: {},
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.SITE_ID,
              label: item.SITE_ID
            }
          })
        }
      },
    }, {
      "key": "TO_SITE_ID",
      "label": "到达站点ID",
      "type": "select",
      "placeholder": "请选择到达站点ID",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": async () => {
        let {status, data: {list}, message} = await setSelectSiteList({
          SITE: {},
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.SITE_ID,
              label: item.SITE_ID
            }
          })
        }
      },
    }, {
      "key": "SHIP_DOCK",
      "label": "发货月台",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入发货月台",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "PRIORITY",
      "label": "优先级 ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入优先级 ",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "WORK_GROUP",
      "label": "工作组",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入工作组",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CONSIGNMENT",
      "label": "托运",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入托运",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ALLOCATION_PRIORITY",
      "label": "分配优先级",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入分配优先级",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SHIPMENT_GROUP",
      "label": "发货组",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入发货组",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ORDER_TYPE",
      "label": "类型",
      "type": "select",
      "placeholder": "请选择类型",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": []
    }, {
      "key": "ORDER_DATE",
      "label": "订单日期",
      "type": "date",
      "placeholder": "请选择订单日期",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ORDER_TIME",
      "label": "订单时间 ",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择订单时间 ",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ORDER_WEIGHT",
      "label": "订单重量",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入订单重量",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ORDER_VOLUME",
      "label": "订单量",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入订单量",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "NUM_LINES",
      "label": "行数",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入行数",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CREATED_BY",
      "label": "创建人",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入创建人",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CREATION_DATE",
      "label": "创建日期",
      "type": "date",
      "placeholder": "请选择创建日期",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CREATION_TIME",
      "label": "创建时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择创建时间",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "LAST_UPDATED_BY",
      "label": "最新更新人",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入最新更新人",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "LAST_UPDATE_DATE",
      "label": "最后更新日期",
      "type": "date",
      "placeholder": "请选择最后更新日期",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "LAST_UPDATE_TIME",
      "label": "最后更新时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择最后更新时间",
      "tabGroup": "mqs_orderheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "REPACK_LOC_ID",
      "label": "重新打包场所",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入重新打包场所",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "REPACK",
      "label": "重新打包",
      "type": "select",
      "placeholder": "请选择重新打包",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "LOAD_SEQUENCE",
      "label": "装载顺序",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入装载顺序",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HIGHEST_LABEL",
      "label": "最高的标签",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入最高的标签",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DISALLOW_MERGE_RULES",
      "label": "忽略合并规则",
      "type": "select",
      "placeholder": "请选择忽略合并规则",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "ORDER_SOURCE",
      "label": "订单源 ",
      "type": "select",
      "placeholder": "请选择订单源 ",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": []
    }, {
      "key": "WORK_ORDER_TYPE",
      "label": "工作订单类型",
      "type": "select",
      "placeholder": "请选择工作订单类型",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": ORDER_HEADER_ORDER_TYPE,
      "formatter": ({cellValue, row, column}) => {
        let res = ORDER_HEADER_ORDER_TYPE.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "SINGLE_ORDER_SORTATION",
      "label": "单一订单分拣",
      "type": "select",
      "placeholder": "请选择单一订单分拣",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "STATUS_REASON_CODE",
      "label": "状态原因",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入状态原因",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "STAGE_ROUTE_ID",
      "label": "分段路线ID ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入分段路线ID ",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SOFT_ALLOCATED",
      "label": "预分配",
      "type": "select",
      "placeholder": "请选择预分配",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "DISALLOW_SHORT_SHIP",
      "label": "不允许短发",
      "type": "select",
      "placeholder": "请选择不允许短发",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "TOTAL_REPACK_CONTAINERS",
      "label": "包装箱数",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入包装箱数",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "WORK_GROUP_GROUPED",
      "label": "组到工作组",
      "type": "select",
      "placeholder": "请选择组到工作组",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "ORDER_GROUPING_ID",
      "label": "订单组ID",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入订单组ID",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CONSIGNMENT_GROUPED",
      "label": "分组托运单",
      "type": "select",
      "placeholder": "请选择分组托运单",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "SHIPMENT_GROUP_GROUPED",
      "label": "是否组合发货组",
      "type": "select",
      "placeholder": "请选择是否组合发货组",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "ORDER_VALUE",
      "label": "订单价值",
      "type": "numrange",
      "placeholder": "请选择订单价值",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "GROSS_WEIGHT",
      "label": "毛重",
      "type": "numrange",
      "placeholder": "请选择毛重",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "EXPORT",
      "label": "出口",
      "type": "select",
      "placeholder": "请选择出口",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "EXPECTED_WEIGHT",
      "label": "预计重量 ",
      "type": "numrange",
      "placeholder": "请选择预计重量 ",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "EXPECTED_VOLUME",
      "label": "预计体积 ",
      "type": "numrange",
      "placeholder": "请选择预计体积 ",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "EXPECTED_VALUE",
      "label": "预计价值 ",
      "type": "numrange",
      "placeholder": "请选择预计价值 ",
      "tabGroup": "mqs_orderheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "LANGUAGE",
      "label": "语言",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入语言",
      "tabGroup": "mqs_orderheader_tab1a",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SELLER_NAME",
      "label": "卖方联系人 ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入卖方联系人 ",
      "tabGroup": "mqs_orderheader_tab1a",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SELLER_PHONE",
      "label": "卖方联系人电话 ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入卖方联系人电话 ",
      "tabGroup": "mqs_orderheader_tab1a",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DOCUMENTATION_TEXT_1",
      "label": "单据文本 1 ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入单据文本 1 ",
      "tabGroup": "mqs_orderheader_tab1a",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DOCUMENTATION_TEXT_2",
      "label": "单据文本 2 ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入单据文本 2 ",
      "tabGroup": "mqs_orderheader_tab1a",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DOCUMENTATION_TEXT_3",
      "label": "单据文本 3 ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入单据文本 3 ",
      "tabGroup": "mqs_orderheader_tab1a",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TOD",
      "label": "交付方式",
      "type": "select",
      "placeholder": "请选择交付方式",
      "tabGroup": "mqs_orderheader_tab1a",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": async () => {
        let {status, data: {list}, message} = await setTermsOwnerList({
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.TOD,
              label: item.NOTES
            }
          })
        }
      },
    }, {
      "key": "TOD_PLACE",
      "label": "交付地点",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入交付地点",
      "tabGroup": "mqs_orderheader_tab1a",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED_WS2PC_ID",
      "label": "Web服务上传ID",
      "type": "numrange",
      "placeholder": "请选择Web服务上传ID",
      "tabGroup": "mqs_orderheader_tab1a",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED_FILENAME",
      "label": "上传文件名",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入上传文件名",
      "tabGroup": "mqs_orderheader_tab1a",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED_DSTAMP",
      "label": "上传日期",
      "type": "date",
      "placeholder": "请选择上传日期",
      "tabGroup": "mqs_orderheader_tab1a",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED_TIME",
      "label": "上传时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择上传时间",
      "tabGroup": "mqs_orderheader_tab1a",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "START_BY_DATE",
      "label": "开始日期",
      "type": "date",
      "placeholder": "请选择开始日期",
      "tabGroup": "mqs_orderheader_tab1a",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "START_BY_TIME",
      "label": "开始时间 ",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择开始时间 ",
      "tabGroup": "mqs_orderheader_tab1a",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED",
      "label": "已卸载",
      "type": "select",
      "placeholder": "请选择是否卸载",
      "tabGroup": "mqs_orderheader_tab1a",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "ORDER_CLOSED",
      "label": "关闭订单",
      "type": "select",
      "placeholder": "请选择关闭订单",
      "tabGroup": "mqs_orderheader_tab1b",
      "tabGroupName": "用户自定义",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "CLOSURE_DATE",
      "label": "关闭日期 ",
      "type": "date",
      "placeholder": "请选择关闭日期 ",
      "tabGroup": "mqs_orderheader_tab1b",
      "tabGroupName": "用户自定义",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CLOSURE_TIME",
      "label": "关闭时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择关闭时间",
      "tabGroup": "mqs_orderheader_tab1b",
      "tabGroupName": "用户自定义",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "MASTER_ORDER",
      "label": "是否主订单",
      "type": "select",
      "placeholder": "请选择是否主订单",
      "tabGroup": "mqs_orderheader_tab1b",
      "tabGroupName": "用户自定义",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "MASTER_ORDER_ID",
      "label": "主订单号码",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入主订单号码",
      "tabGroup": "mqs_orderheader_tab1b",
      "tabGroupName": "用户自定义",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ALLOW_PALLET_PICK",
      "label": "允许托盘拣选",
      "type": "select",
      "placeholder": "请选择允许托盘拣选",
      "tabGroup": "mqs_orderheader_tab1b",
      "tabGroupName": "用户自定义",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "SPLIT_SHIPPING_UNITS",
      "label": "拆分到发货单位",
      "type": "select",
      "placeholder": "请选择拆分到发货单位",
      "tabGroup": "mqs_orderheader_tab1b",
      "tabGroupName": "用户自定义",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "VOL_PCK_SSCC_LABEL",
      "label": "海关铅封",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入海关铅封",
      "tabGroup": "mqs_orderheader_tab1b",
      "tabGroupName": "用户自定义",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "PROFORMA_INVOICE_NUM",
      "label": "形式发票号码",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入形式发票号码",
      "tabGroup": "mqs_orderheader_tab1a",
      "tabGroupName": "用户自定义",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DISPATCH_METHOD",
      "label": "调度方法",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入调度方法",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DELIVERY_POINT",
      "label": "卸货点",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入卸货点",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SIGNATORY",
      "label": "签名人 ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入签名人 ",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CARRIER_ID",
      "label": "承运商",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入承运商",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SERVICE_LEVEL",
      "label": "服务级别 ",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入服务级别 ",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SHIP_BY_DATE",
      "label": "按日期运送",
      "type": "date",
      "placeholder": "按日期运送",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SHIP_BY_TIME",
      "label": "按时间运送",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "按时间运送",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SHIPPED_DATE",
      "label": "运送日期",
      "type": "date",
      "placeholder": "请选择运送日期",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SHIPPED_TIME",
      "label": "运送时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "运送时间",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DELIVER_BY_DATE",
      "label": "按日期交货",
      "type": "date",
      "placeholder": "按日期交货",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DELIVER_BY_TIME",
      "label": "按时间交货",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "按时间交货",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DELIVERED_DSTAMP",
      "label": "交货日期",
      "type": "date",
      "placeholder": "请选择交货日期",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DELIVERED_TIME",
      "label": "交货时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择交货时间",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "FORCE_SINGLE_CARRIER",
      "label": "强制一个承运商",
      "type": "select",
      "placeholder": "请选择强制一个承运商",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "SHIP_BY_DATE_ERR",
      "label": "发货日期计算错误",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入发货日期计算错误",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SHIP_BY_DATE_ERR_MSG",
      "label": "发货日期错误信息",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入发货日期错误信息",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DEL_BY_DATE_ERR",
      "label": "交货日期计算错误",
      "type": "select",
      "placeholder": "请选择交货日期计算错误",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "DEL_BY_DATE_ERR_MSG",
      "label": "交货日期错误信息",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入交货日期错误信息",
      "tabGroup": "mqs_orderheader_tab2",
      "tabGroupName": "交货详细信息",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CUSTOMER_ID",
      "label": "送货客户",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入送货客户",
      "tabGroup": "mqs_orderheader_tab3",
      "tabGroupName": "送货地址",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "NAME",
      "label": "客户名称",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入客户名称",
      "tabGroup": "mqs_orderheader_tab3",
      "tabGroupName": "送货地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CONTACT",
      "label": "联系人",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入联系人",
      "tabGroup": "mqs_orderheader_tab3",
      "tabGroupName": "送货地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ADDRESS1",
      "label": "地址1 ",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入地址1 ",
      "tabGroup": "mqs_orderheader_tab3",
      "tabGroupName": "送货地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CONTACT_PHONE",
      "label": "电话",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入电话",
      "tabGroup": "mqs_orderheader_tab3",
      "tabGroupName": "送货地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ADDRESS2",
      "label": "地址2",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入地址2",
      "tabGroup": "mqs_orderheader_tab3",
      "tabGroupName": "送货地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CONTACT_FAX",
      "label": "传真",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入传真",
      "tabGroup": "mqs_orderheader_tab3",
      "tabGroupName": "送货地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TOWN",
      "label": "城镇",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入城镇",
      "tabGroup": "mqs_orderheader_tab3",
      "tabGroupName": "送货地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CONTACT_EMAIL",
      "label": "电子邮件",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入电子邮件",
      "tabGroup": "mqs_orderheader_tab3",
      "tabGroupName": "送货地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "COUNTY",
      "label": "县市",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入县市",
      "tabGroup": "mqs_orderheader_tab3",
      "tabGroupName": "送货地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CONTACT_MOBILE",
      "label": "手机",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入手机",
      "tabGroup": "mqs_orderheader_tab3",
      "tabGroupName": "送货地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "POSTCODE",
      "label": "邮政编码 ",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入邮政编码 ",
      "tabGroup": "mqs_orderheader_tab3",
      "tabGroupName": "送货地址",
      "showBlock": ["all"],
      "checked": false,
      defaultUpper:true,
      "sheetName": "head"
    }, {
      "key": "VAT_NUMBER",
      "label": "VAT Number",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入VAT Number",
      "tabGroup": "mqs_orderheader_tab3",
      "tabGroupName": "送货地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "COUNTRY",
      "label": " 国家/地区",
      "type": "select",
      "placeholder": "请选择 国家/地区",
      "tabGroup": "mqs_orderheader_tab3",
      "tabGroupName": "送货地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": async () => {
        let {status, data: {list}, message} = await GetPageTableColumnItem({
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.ISO3_ID,
              label: item.ISO3_ID
            }
          })
        }
      },
    }, {
      "key": "GLN",
      "label": "GLN",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入GLN",
      "tabGroup": "mqs_orderheader_tab3",
      "tabGroupName": "送货地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_ADDRESS_ID",
      "label": "客户（发货）",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入客户（发货）",
      "tabGroup": "mqs_orderheader_tab4",
      "tabGroupName": "发票地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "PURCHASE_ORDER",
      "label": "采购订单号",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入采购订单号",
      "tabGroup": "mqs_orderheader_tab4",
      "tabGroupName": "发票地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_NAME",
      "label": "发票名称",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入发票名称",
      "tabGroup": "mqs_orderheader_tab4",
      "tabGroupName": "发票地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_CONTACT",
      "label": "发货联系人 ",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "发货联系人",
      "tabGroup": "mqs_orderheader_tab4",
      "tabGroupName": "发票地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_ADDRESS1",
      "label": "发货地址1",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入发货地址1",
      "tabGroup": "mqs_orderheader_tab4",
      "tabGroupName": "发票地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_ADDRESS2",
      "label": "发货地址2",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入发货地址2",
      "tabGroup": "mqs_orderheader_tab4",
      "tabGroupName": "发票地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_CONTACT_PHONE",
      "label": "发货电话",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "发货电话",
      "tabGroup": "mqs_orderheader_tab4",
      "tabGroupName": "发票地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_CONTACT_FAX",
      "label": "发货传真",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "发货传真",
      "tabGroup": "mqs_orderheader_tab4",
      "tabGroupName": "发票地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_TOWN",
      "label": "发货城市",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "发货城市",
      "tabGroup": "mqs_orderheader_tab4",
      "tabGroupName": "发票地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_CONTACT_EMAIL",
      "label": "发货Email",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "发货Email",
      "tabGroup": "mqs_orderheader_tab4",
      "tabGroupName": "发票地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_COUNTY",
      "label": "发货县市",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入发货县市",
      "tabGroup": "mqs_orderheader_tab4",
      "tabGroupName": "发票地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_CONTACT_MOBILE",
      "label": "发货手机",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "发货手机",
      "tabGroup": "mqs_orderheader_tab4",
      "tabGroupName": "发票地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_POSTCODE",
      "label": "发货邮政编码",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "发货邮政编码 ",
      "tabGroup": "mqs_orderheader_tab4",
      "tabGroupName": "发票地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_VAT_NUMBER",
      "label": "Invoice Vat Number",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "Invoice Vat Number",
      "tabGroup": "mqs_orderheader_tab4",
      "tabGroupName": "发票地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_COUNTRY",
      "label": "发货国家/地区 ",
      "type": "select",
      "placeholder": "请选择发货国家/地区",
      "tabGroup": "mqs_orderheader_tab4",
      "tabGroupName": "发票地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": async () => {
        let {status, data: {list}, message} = await GetPageTableColumnItem({
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.ISO3_ID,
              label: item.ISO3_ID
            }
          })
        }
      },
    }, {
      "key": "INV_GLN",
      "label": "Invoice GLN",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "Invoice GLN",
      "tabGroup": "mqs_orderheader_tab4",
      "tabGroupName": "发票地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HUB_ADDRESS_ID",
      "label": "集散中心 ",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "集散中心",
      "tabGroup": "mqs_orderheader_tab5",
      "tabGroupName": "Hub地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HUB_SERVICE_LEVEL",
      "label": "Hub服务级别",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入Hub服务级别",
      "tabGroup": "mqs_orderheader_tab5",
      "tabGroupName": "Hub地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HUB_NAME",
      "label": "Hub名称",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入Hub名称",
      "tabGroup": "mqs_orderheader_tab5",
      "tabGroupName": "Hub地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HUB_CONTACT",
      "label": "Hub联系地址 ",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入Hub联系地址 ",
      "tabGroup": "mqs_orderheader_tab5",
      "tabGroupName": "Hub地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HUB_ADDRESS1",
      "label": "Hub地址1 ",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入Hub地址1 ",
      "tabGroup": "mqs_orderheader_tab5",
      "tabGroupName": "Hub地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HUB_CONTACT_PHONE",
      "label": "Hub电话号码 ",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入Hub电话号码 ",
      "tabGroup": "mqs_orderheader_tab5",
      "tabGroupName": "Hub地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HUB_ADDRESS2",
      "label": "Hub地址2 ",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入Hub地址2 ",
      "tabGroup": "mqs_orderheader_tab5",
      "tabGroupName": "Hub地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HUB_CONTACT_FAX",
      "label": "Hub传真号 ",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入Hub传真号 ",
      "tabGroup": "mqs_orderheader_tab5",
      "tabGroupName": "Hub地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HUB_TOWN",
      "label": "Hub所在城镇 ",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入Hub所在城镇 ",
      "tabGroup": "mqs_orderheader_tab5",
      "tabGroupName": "Hub地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HUB_CONTACT_EMAIL",
      "label": "Hub电子邮件地址 ",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入Hub电子邮件地址 ",
      "tabGroup": "mqs_orderheader_tab5",
      "tabGroupName": "Hub地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HUB_COUNTY",
      "label": "Hub县市",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入Hub县市",
      "tabGroup": "mqs_orderheader_tab5",
      "tabGroupName": "Hub地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HUB_CONTACT_MOBILE",
      "label": "Hub联系人手机",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入Hub联系人手机",
      "tabGroup": "mqs_orderheader_tab5",
      "tabGroupName": "Hub地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HUB_POSTCODE",
      "label": "Hub邮政编码",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入Hub邮政编码",
      "tabGroup": "mqs_orderheader_tab5",
      "tabGroupName": "Hub地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HUB_CARRIER_ID",
      "label": "交货到Hub的承运商 ",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入交货到Hub的承运商 ",
      "tabGroup": "mqs_orderheader_tab5",
      "tabGroupName": "Hub地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HUB_COUNTRY",
      "label": "国家",
      "type": "select",
      "placeholder": "请选择国家",
      "tabGroup": "mqs_orderheader_tab5",
      "tabGroupName": "Hub地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": async () => {
        let {status, data: {list}, message} = await GetPageTableColumnItem({
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.ISO3_ID,
              label: item.ISO3_ID
            }
          })
        }
      },
    }, {
      "key": "HUB_VAT_NUMBER",
      "label": "Hub增值税号码",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入Hub增值税号码",
      "tabGroup": "mqs_orderheader_tab5",
      "tabGroupName": "Hub地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HUB_GLN",
      "label": "国际地址代码",
      "type": "magnifier",
      "tableName": 'ORDER_HEADER',
      "placeholder": "请输入国际地址代码",
      "tabGroup": "mqs_orderheader_tab5",
      "tabGroupName": "Hub地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ORDER_REFERENCE",
      "label": "订单参考",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入订单参考",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "COD",
      "label": "货到付款 ",
      "type": "select",
      "placeholder": "请选择货到付款 ",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "COD_VALUE",
      "label": "货到付款金额",
      "type": "numrange",
      "placeholder": "请选择货到付款金额",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "COD_CURRENCY",
      "label": "货到付款币种",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入货到付款币种",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "COD_TYPE",
      "label": "货到付款类型",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入货到付款类型",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "PRINT_INVOICE",
      "label": "打印发票",
      "type": "select",
      "placeholder": "请选择打印发票",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "INV_REFERENCE",
      "label": "发票号码",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入发票号码",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_DSTAMP",
      "label": "发票日期",
      "type": "date",
      "placeholder": "请选择发票日期",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_TIME",
      "label": "发票时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择发票时间",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_CURRENCY",
      "label": "发票币种",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入发票币种",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_TOTAL_1",
      "label": "发票金额合计1",
      "type": "numrange",
      "placeholder": "请选择发票金额合计1",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_TOTAL_2",
      "label": "发票金额合计2",
      "type": "numrange",
      "placeholder": "请选择发票金额合计2",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_TOTAL_3",
      "label": "发票金额合计3",
      "type": "numrange",
      "placeholder": "请选择发票金额合计3",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INV_TOTAL_4",
      "label": "发票金额合计4",
      "type": "numrange",
      "placeholder": "请选择发票金额合计4",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "LETTER_OF_CREDIT",
      "label": "信用证",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入信用证",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "PAYMENT_TERMS",
      "label": "付款条件",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入付款条件",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "FREIGHT_COST",
      "label": "运费成本 ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入运费成本 ",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "FREIGHT_TERMS",
      "label": "运费周期 ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入运费周期 ",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "INSURANCE_COST",
      "label": "保险费",
      "type": "numrange",
      "placeholder": "请选择保险费",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "MISC_CHARGES",
      "label": "杂费",
      "type": "numrange",
      "placeholder": "请选择杂费",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DISCOUNT",
      "label": "折扣",
      "type": "numrange",
      "placeholder": "请选择折扣",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "OTHER_FEE",
      "label": "其他费用",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入其他费用",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "FREIGHT_CURRENCY",
      "label": "运费币种 ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入运费币种 ",
      "tabGroup": "mqs_orderheader_tab6",
      "tabGroupName": "财务",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SUBTOTAL_1",
      "label": "小计1",
      "type": "numrange",
      "placeholder": "请选择小计1",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SUBTOTAL_2",
      "label": "小计2",
      "type": "numrange",
      "placeholder": "请选择小计2",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SUBTOTAL_3",
      "label": "小计3",
      "type": "numrange",
      "placeholder": "请选择小计3",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SUBTOTAL_4",
      "label": "小计4",
      "type": "numrange",
      "placeholder": "请选择小计4",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TAX_RATE_1",
      "label": "税率1",
      "type": "numrange",
      "placeholder": "请选择税率1",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TAX_BASIS_1",
      "label": "交税基数1",
      "type": "numrange",
      "placeholder": "请选择交税基数1",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TAX_AMOUNT_1",
      "label": "税额1",
      "type": "numrange",
      "placeholder": "请选择税额1",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TAX_RATE_2",
      "label": "税率2",
      "type": "numrange",
      "placeholder": "请选择税率2",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TAX_BASIS_2",
      "label": "交税基数2",
      "type": "numrange",
      "placeholder": "请选择交税基数2",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TAX_AMOUNT_2",
      "label": "税额2",
      "type": "numrange",
      "placeholder": "请选择税额2",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TAX_RATE_3",
      "label": "税率3",
      "type": "numrange",
      "placeholder": "请选择税率3",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TAX_BASIS_3",
      "label": "交税基数3",
      "type": "numrange",
      "placeholder": "请选择交税基数3",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TAX_AMOUNT_3",
      "label": "税额3",
      "type": "numrange",
      "placeholder": "请选择税额3",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TAX_RATE_4",
      "label": "税率4",
      "type": "numrange",
      "placeholder": "请选择税率4",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TAX_BASIS_4",
      "label": "交税基数4",
      "type": "numrange",
      "placeholder": "请选择交税基数4",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TAX_AMOUNT_4",
      "label": "税额4",
      "type": "numrange",
      "placeholder": "请选择税额4",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TAX_RATE_5",
      "label": "税率5",
      "type": "numrange",
      "placeholder": "请选择税率5",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TAX_BASIS_5",
      "label": "交税基数5",
      "type": "numrange",
      "placeholder": "请选择交税基数5",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TAX_AMOUNT_5",
      "label": "税额5",
      "type": "numrange",
      "placeholder": "请选择税额5",
      "tabGroup": "mqs_orderheader_tab7",
      "tabGroupName": "财务2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_1",
      "label": "司机姓名 ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "司机姓名",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_2",
      "label": "报检号",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "报检号",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_3",
      "label": "集装箱号",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "集装箱号",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_4",
      "label": "客户单号",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "客户单号",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_5",
      "label": "卡车牌号",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "卡车牌号",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_6",
      "label": "库房",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "库房",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_7",
      "label": "送货地址",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "送货地址",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_8",
      "label": "封条号码",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "封条号码",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_CHK_1",
      "label": "今日订单",
      "type": "select",
      "placeholder": "今日订单",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_2",
      "label": "PFS卸货",
      "type": "select",
      "placeholder": "PFS卸货",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_3",
      "label": "日常OT",
      "type": "select",
      "placeholder": "日常OT",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_4",
      "label": "节假日OT ",
      "type": "select",
      "placeholder": "节假日OT ",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_DATE_1",
      "label": "预约出库日期",
      "type": "date",
      "placeholder": "预约出库日期",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_1",
      "label": "司机到达时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "司机到达时间",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_DATE_2",
      "label": "用户自定义日期 2 ",
      "type": "date",
      "placeholder": "用户自定义日期 2 ",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_2",
      "label": "司机离开时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "司机离开时间",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_DATE_3",
      "label": "用户自定义日期 3 ",
      "type": "date",
      "placeholder": "用户自定义日期 3 ",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_3",
      "label": "开始装货时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "开始装货时间",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_DATE_4",
      "label": "用户自定义日期 4 ",
      "type": "date",
      "placeholder": "用户自定义日期 4 ",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_4",
      "label": "完成装货时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "完成装货时间",
      "tabGroup": "user_def_oh_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_1",
      "label": "总重量（吨）",
      "type": "numrange",
      "placeholder": "总重量（吨）",
      "tabGroup": "user_def_oh_tab1",
      "tabGroupName": "用户自定义的_设置2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_2",
      "label": "要求装车温度 ",
      "type": "numrange",
      "placeholder": "要求装车温度 ",
      "tabGroup": "user_def_oh_tab1",
      "tabGroupName": "用户自定义的_设置2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_3",
      "label": "产品出库温度",
      "type": "numrange",
      "placeholder": "产品出库温度",
      "tabGroup": "user_def_oh_tab1",
      "tabGroupName": "用户自定义的_设置2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_4",
      "label": "用户自定义号码 4 ",
      "type": "numrange",
      "placeholder": "请选择用户自定义号码 4 ",
      "tabGroup": "user_def_oh_tab1",
      "tabGroupName": "用户自定义的_设置2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NOTE_1",
      "label": "用户自定义注释 1 ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入用户自定义注释 1 ",
      "tabGroup": "user_def_oh_tab1",
      "tabGroupName": "用户自定义的_设置2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NOTE_2",
      "label": "用户自定义注释 2 ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入用户自定义注释 2 ",
      "tabGroup": "user_def_oh_tab1",
      "tabGroupName": "用户自定义的_设置2",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ALLOCATION_RUN",
      "label": "分配运行",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入分配运行",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ARCHIVED",
      "label": "是否存档",
      "type": "select",
      "placeholder": "请选择是否存档",
      "showBlock": [],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "CE_CUSTOMS_CUSTOMER",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CE_EXCISE_CUSTOMER",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CE_INSTRUCTIONS",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CE_ORDER_TYPE",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CE_REASON_CODE",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CE_REASON_NOTES",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CHEAPEST_CARRIER",
      "label": "最便宜的承运商",
      "type": "select",
      "placeholder": "请选择最便宜的承运商",
      "showBlock": [],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "CID_NUMBER",
      "label": "提货单",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入提货单",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CROSS_DOCK_TO_SITE",
      "label": "目的站点越库",
      "type": "select",
      "placeholder": "请选择目的站点越库",
      "showBlock": [],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "EXCLUDE_POSTCODE",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "FASTEST_CARRIER",
      "label": "最快承运商",
      "type": "select",
      "placeholder": "请选择最快承运商",
      "showBlock": [],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "FREIGHT_CHARGES",
      "label": "运费支付方法",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入运费支付方法",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "LOCATION_NUMBER",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "METAPACK_CARRIER_PRE",
      "label": "",
      "type": "select",
      "placeholder": "请选择",
      "showBlock": [],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "MPACK_CONSIGNMENT",
      "label": "快递单号",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入快递单号",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "MPACK_NOMINATED_DSTAMP",
      "label": "快递日期",
      "type": "date",
      "placeholder": "请选择快递日期",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "MPACK_PACK_DSTAMP",
      "label": "",
      "type": "date",
      "placeholder": "请选择",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "MPACK_PACK_ERR",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "MPACK_PRE_CAR_DSTAMP",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "MPACK_PRE_CAR_ERR",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "MRN",
      "label": "MRN",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入MRN",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "NCTS",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "NO_SHIPMENT_EMAIL",
      "label": "是否",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入是否",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ORDER_ID_LINK",
      "label": "识符",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入识符",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "PSFT_DMND_SRCE",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "PSFT_ORDER_ID",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ROUTE_ID",
      "label": "路线标识 ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入路线标识 ",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ROUTE_PLANNED",
      "label": "",
      "type": "select",
      "placeholder": "请选择",
      "showBlock": [],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "SID_NUMBER",
      "label": "装载sid号帐单 ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入装载sid号帐单 ",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SITE_REPLEN",
      "label": "站点补货 ",
      "type": "select",
      "placeholder": "请选择站点补货 ",
      "showBlock": [],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "TM_STOP_NAM",
      "label": "Stop Name, as provided by the Transport Management System ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入Stop Name, as provided by the Transport Management System ",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TM_STOP_SEQ",
      "label": "Stop Sequence, as provided by the Transport Management System ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入Stop Sequence, as provided by the Transport Management System ",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TRAX_ID",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED_CUSTOMS",
      "label": "上传到海关系统",
      "type": "select",
      "placeholder": "请选择上传到海关系统",
      "showBlock": [],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "UPLOADED_HEADER_KEY",
      "label": "已上传表头关键值 ",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入已上传表头关键值 ",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED_LABOR",
      "label": "上传到劳动力系统",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入上传到劳动力系统",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED_VVIEW",
      "label": "上传到虚拟系统",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入上传到虚拟系统",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "WEB_SERVICE_ALLOC_CLEAN",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "WEB_SERVICE_ALLOC_IMMED",
      "label": "对于指定你希望立即分配一个订单",
      "type": "autoComplete",
      "tableName": "ORDER_HEADER",
      "placeholder": "请输入对于指定你希望立即分配一个订单",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }]
  }
}